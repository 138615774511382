.footer {
  padding: 50px 0;
  color: #ffffff;
}

.footer-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 0 100px;
}

.footer-left,
.footer-right {
  background-color: transparent;
  padding: 1rem;
  border-radius: 10px;
}

.footer-left h2 {
  font-size: 24px;
}

.footer-left p {
  font-size: 14px;
  line-height: 1.6;
}

.social-icons {
  margin-top: 20px;
}

.social-icons a {
  margin-right: 10px;
}

.social-icons img {
  width: 24px;
  height: 24px;
}

.footer-navigation,
.footer-contact,
.footer-subscription {
  margin-bottom: 20px;
}

.footer-navigation h4,
.footer-contact h4,
.footer-subscription h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-navigation ul {
  list-style: none;
  padding: 0;
}

.footer-navigation li {
  margin-bottom: 10px;
}

.footer-navigation a {
  color: #ffffff;
  text-decoration: none;
}

.footer-navigation a:hover {
  text-decoration: underline;
}

.footer-contact p,
.footer-contact a {
  margin: 5px 0;
  color: #ffffff;
  text-decoration: none;
}

.footer-contact a:hover {
  text-decoration: underline;
}

.subscription-form {
  display: flex;
  align-items: center;
}

.subscription-form input {
  padding: 10px;
  border: none;
  border-radius: 5px 0 0 5px;
  outline: none;
}

.subscription-form button {
  padding: 10px;
  border: none;
  border-radius: 0 5px 5px 0;
  background-color: #00bfa6; /* Adjust to match your design */
  cursor: pointer;
}

.subscription-form button img {
  width: 20px;
  height: 20px;
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .footer-container {
    grid-template-columns: 1fr;
  }
}
