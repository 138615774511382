body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.portfolio {
  color: #fff;
  background-color: #001417;
  min-height: 100vh;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.logo {
  font-family: 'Cursive';
  color: #00e0d0;
  font-size: 24px;
}

.nav a {
  color: #fff;
  text-decoration: none;
  margin-left: 20px;
  font-size: 18px;
}

.main-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
}

.text-content {
  margin-left: 15px;
  margin-bottom: 20px;
}

.text-content h2 {
  font-size: 24px;
}

.name {
  font-family: 'Cursive';
  color: #00e0d0;
  font-size: 48px;
  margin: 10px 0;
}

.title {
  font-size: 32px;
  margin: 10px 0;
}

.text-content p {
  margin: 20px 0;
  line-height: 1.6;
}

.download-btn {
  background-color: #00e0d0;
  color: #0a0b1e;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 20px;
}

.social-links a {
  color: #00e0d0;
  margin-right: 10px;
  font-size: 24px;
}

.image-content {
  max-width: 400px;
  width: 100%;
}

.image-content img {
  width: 100%;
  border-radius: 20px;
  transform: rotate(10deg);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    align-items: center;
  }

  .text-content {
    text-align: center;
  }

  .image-content {
    margin-top: 20px;
    float: left;
  }

  .image-content img {
    transform: rotate(0deg);
  }
}

/*Nav modification*/

.nav {
  display: flex;
  align-items: center;
}

.nav-links {
  display: flex;
}

.nav-toggle {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #001417;
    padding: 20px;
    z-index: 1;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-toggle {
    display: block;
  }
}
